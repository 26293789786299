import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/ActivityBuilder.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function ExerciseListElement( {name, level, type, time, onAddExercise, canDelete, onRemoveExercise, onMoveListener} ) {
  return (
    <div className='activityInActivityBuilder'>
        {canDelete &&<button className='patientBubbleRemove' onClick={onRemoveExercise}>-</button>}
        {onAddExercise!=null&&<button className='plusButton' onClick={onAddExercise}>+</button>}
        <div className='exerciseNameInActivityBuilder'>{name}</div>
        {type!=null&&<div className='typeText'> {type}</div>}
        <div className='levelAndTimeText'> Level: {level}</div>
        {time!=null&&<div className='levelAndTimeText'>{time}</div>}
        {onMoveListener!=null&&<div className='reorderArea'>
            <button className='moveUpButton' onClick={() => onMoveListener(-1)}>▲</button>
            <button className='moveDownButton' onClick={() => onMoveListener(1)}>▼</button>
        </div>}
    </div>
  );
}

export default ExerciseListElement;
