import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import TopBar from "../BaseElements/TopBar/TopBar";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState} from "react";

function AddUser() {
    const navigate = useNavigate();
    const username = localStorage.getItem('username');
    const [patientId, setPatientId] = useState('');
    const [patientName, setPatientName] = useState('');
    const [patientPassword, setPatientPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    
    const createPatient = async (event) => {
        event.preventDefault(); // Prevent form from submitting and reloading the page
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            if(patientName.length > 0 && patientPassword.length > 0 && repeatedPassword.length > 0) {
                if(patientPassword === repeatedPassword) {
                    const createPatientResponse = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/createPatient`,
                        {patient_id: patientId, patient_name: patientName, patient_password: patientPassword},
                        axiosConfig
                    );
                    if (createPatientResponse.data.success) {
                        alert("Paziente aggiunto correttamente");
                    } else {
                        console.error(createPatientResponse.data.errorMess);
                    }
                }
                else {
                    alert("Le due password non coincidono");
                }
            }
            else {
                alert("Riempi tutti i campi prima di continuare")
            }
            
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <Container className='center'>
            <TopBar text={username} onMenu={() => {navigate('/home')}} onLogout={() => {}} showSearchBar={false}></TopBar>
            <Col xs={10} md={7} xl={6} xxl={6} className='center canScroll'>
                <Form className='loginForm center' onSubmit={createPatient}>
                    <Form.Group className='loginFormComponent' controlId='newUserID'>
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='ID'
                            value={patientId} // Bind value to patientName
                            onChange={(e) => setPatientId(e.target.value)} // Update patientName on change
                        />
                    </Form.Group>
                    <Form.Group className='loginFormComponent' controlId='newUserUsername'>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='Username'
                            value={patientName}
                            onChange={(e) => setPatientName(e.target.value)} // Update patientName on change
                        />
                    </Form.Group>
                    <Form.Group className='loginFormComponent' controlId='newUserPsw'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder='password'
                            value={patientPassword} // Bind value to patientPassword
                            onChange={(e) => setPatientPassword(e.target.value)} // Update patientPassword on change
                        />
                    </Form.Group>
                    <Form.Group className='loginFormComponent' controlId='rptPsw'>
                        <Form.Label>Ripeti Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder='password'
                            value={repeatedPassword}
                            onChange={(e) => setRepeatedPassword(e.target.value)} // Update patientPassword on change
                        />
                    </Form.Group>
                </Form>
                <Button variant="primary" type="submit" className='mt-3' onClick={createPatient}>
                    Crea utente
                </Button>
            </Col>
        </Container>
    );
}

export default AddUser;
