import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Filters/Filter.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'


function Filter( {text, onClick, isClicked} ) {
  return (
    <button onClick={onClick} className={isClicked ? 'clickedFilter' : 'defaultFilter'}>
      <div className='filterText'> {text} </div>
    </button>
   
  );
}

export default Filter;
