// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.therapistName {
    font-size: x-large;
    color: var(--logout-therapist-name-text-color);
}

.logoutText {
    background-color: transparent;
    border: transparent;
    -webkit-text-decoration: underline var(--logout-text-default-color);
            text-decoration: underline var(--logout-text-default-color);
    color: var(--logout-text-default-color);
}

.logoutText:hover {
    -webkit-text-decoration: underline var(--logout-text-hovered-color);
            text-decoration: underline var(--logout-text-hovered-color);
    color: var(--logout-text-hovered-color);
}

.logoutComponent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: end;
    justify-content: space-around;

}`, "",{"version":3,"sources":["webpack://./src/css/BaseElements/TopBar/LogoutComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,8CAA8C;AAClD;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,mEAA2D;YAA3D,2DAA2D;IAC3D,uCAAuC;AAC3C;;AAEA;IACI,mEAA2D;YAA3D,2DAA2D;IAC3D,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;;AAEjC","sourcesContent":[".therapistName {\n    font-size: x-large;\n    color: var(--logout-therapist-name-text-color);\n}\n\n.logoutText {\n    background-color: transparent;\n    border: transparent;\n    text-decoration: underline var(--logout-text-default-color);\n    color: var(--logout-text-default-color);\n}\n\n.logoutText:hover {\n    text-decoration: underline var(--logout-text-hovered-color);\n    color: var(--logout-text-hovered-color);\n}\n\n.logoutComponent {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-items: end;\n    justify-content: space-around;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
