import '../../css/Colors.css'
import '../../css/Text.css'
import '../../css/BaseElements/TopBar/TopBar.css'


import HomeIconButton from '../Buttons/HomeIconButton.js'
import SearchBar from './SearchBar.js'
import LogoutComponent from './LogoutComponent.js'


function TopBar( {text, onMenu, onLogout,showSearchBar, onChange} ) {
  return (
    <div className='topBar'>
    <HomeIconButton onClick={onMenu}></HomeIconButton>
    {showSearchBar&&<SearchBar onChange={onChange}></SearchBar>}
    <LogoutComponent text={text} onClick={onLogout}></LogoutComponent>
    </div>
  );
}

export default TopBar;
