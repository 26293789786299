// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/NewCardBG.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/PatientCardBG.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --new-card-button-bg-img: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    --activity-card: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    --activity-info-bg: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
}`, "",{"version":3,"sources":["webpack://./src/css/Images.css"],"names":[],"mappings":"AAAA;IACI,iEAAwD;IACxD,wDAAmD;IACnD;AACJ","sourcesContent":[":root {\n    --new-card-button-bg-img: url(\"../assets/NewCardBG.svg\");\n    --activity-card: url(\"../assets/PatientCardBG.svg\");\n    --activity-info-bg: url(\"../assets/NewCardBG.svg\")\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
