import '../App.css';
import '../css/Home.css';
import '../css/Colors.css';
import '../css/GeneralCSS.css';
import '../css/Text.css';

import ActivityFilter from '../BaseElements/Filters/ActivityFilter.js';
import PatientFilter from '../BaseElements/Filters/PatientsFilter.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import PatientCard from '../BaseElements/Cards/PatientCard.js';
import ActivityCard from '../BaseElements/Cards/ActivityCard.js';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import isLoggedIn from '../code/utils';
import axios from 'axios';
import TopBar from '../BaseElements/TopBar/TopBar.js';
import NewCard from "../BaseElements/Buttons/NewCardButton";

axios.defaults.withCredentials = true;

function HomePage() {
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [activities, setActivities] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [showListPatients, setShowListPatients] = useState(false);
    const [showListActivities, setShowListActivities] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    
    const username = localStorage.getItem('username');
    
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/");
            return;
        }
        loadData().then();
    }, [navigate]);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const activitiesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverActivities`, axiosConfig);
            const patientsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverPatients`, axiosConfig);
            
            if (activitiesResponse.data.success && patientsResponse.data.success) {
                const activitiesTemp = activitiesResponse.data.data;
                const patientsTemp = patientsResponse.data.data;
                const activitiesMapped = activitiesTemp.map((element) => ({
                    id: element[0],
                    name: element[1],
                    numOfExercises: element[2],
                }));
                const patientsMapped = patientsTemp.map((element) => element[0].toString());
                
                setActivityData(activitiesMapped);
                setPatients(patientsMapped);
                setActivities(activitiesMapped.map((activity) => activity.name));
            } else {
                console.error(activitiesResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const logout = async () => {
        if (!isLoggedIn()) {
            navigate("/");
        } else {
            const axiosConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            try {
                const response = await axios.get('http://localhost:5000/api/logOut', {}, axiosConfig);
                if (response.data.success) {
                    localStorage.removeItem('user');
                    navigate("/");
                } else {
                    alert(response.data.errorMess);
                }
            } catch (error) {
                alert(error.errorMess);
            }
        }
    };
    
    const UpdateSearchValue = (searchText) => {
        setSearchValue(searchText);
    };
    
    function manageFilter(patients, activities) {
        setShowListActivities(activities);
        setShowListPatients(patients);
    }
    
    const filteredPatients = patients.filter((patient) =>
                                                 patient.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    const filteredActivities = activityData.filter((activity) =>
                                                       activity.name.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    
    return (
        <div className='fullPageContainer'>
            <TopBar text={username} onChange={UpdateSearchValue} onMenu={() => navigate('/home')} onLogout={() => { }} showSearchBar={true} />
            <div className='filterArea'>
                <ActivityFilter isClicked={showListActivities} onClick={() => manageFilter(false, true)} />
                <PatientFilter isClicked={showListPatients} onClick={() => manageFilter(true, false)} />
            </div>
            <div className='homeMainContentArea'>
                <div className='homeScrolligArea'>
                    <div className='homeScrollingListElementView'>
                        {showListPatients && filteredPatients.map((patient) => (
                            <PatientCard key={patient} text={patient} onClick={() => navigate(`/patient-info/${patient}`)} />
                        ))}
                        {showListActivities && filteredActivities.map((activity) => (
                            <ActivityCard
                                key={activity.id}
                                text={activity.name}
                                exercisesNumber={activity.numOfExercises}
                                onClick={() => navigate(`/activity-info/${activity.id}`)}
                            />
                        ))}
                    </div>
                </div>
                <div className='newCardArea'>
                    {showListPatients && <NewCard text={'Nuovo Paziente'} onClick={() => navigate('/add-user')} />}
                    {showListActivities && <NewCard text={'Crea Attività'} onClick={() => navigate('/create-activity')} />}
                </div>
            </div>
        </div>
    );
}

export default HomePage;
