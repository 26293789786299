import React, { useState } from 'react';

const FilterRow = ({ types, levels, times, onFilterChange }) => {
    const [showTypeDropdown, setShowTypeDropdown] = useState(false);
    const [showLevelDropdown, setShowLevelDropdown] = useState(false);
    const [showTimeDropdown, setShowTimeDropdown] = useState(false);
    
    // Local state for the selected filters
    const [selectedType, setSelectedType] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    
    // Sort levels and times
    const sortedLevels = [...levels].sort((a, b) => a - b); // Numerical sorting for levels
    const sortedTimes = [...times].sort((a, b) => a - b); // Numerical sorting for times
    
    const handleFilterChange = (filterType, value) => {
        switch (filterType) {
            case 'type':
                setSelectedType(value);
                break;
            case 'level':
                setSelectedLevel(value);
                break;
            case 'time':
                setSelectedTime(value);
                break;
            default:
                break;
        }
        onFilterChange(filterType, value);
    };
    
    // Reset filters
    const resetFilters = () => {
        setSelectedType(null);
        setSelectedLevel(null);
        setSelectedTime(null);
        
        // Notify parent component to reset filters
        onFilterChange('type', null);
        onFilterChange('level', null);
        onFilterChange('time', null);
    };
    
    return (
        <div className="filterRow">
            {/* Type Dropdown */}
            <div className="filterType" onClick={() => setShowTypeDropdown(!showTypeDropdown)}>
                Type: {selectedType || "All"}
                {showTypeDropdown && (
                    <ul className="dropdown">
                        {types.map((type, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    handleFilterChange('type', type);
                                    setShowTypeDropdown(false); // Close dropdown
                                }}
                            >
                                {type}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            
            {/* Level Dropdown */}
            <div className="filterLevel" onClick={() => setShowLevelDropdown(!showLevelDropdown)}>
                Level: {selectedLevel || "All"}
                {showLevelDropdown && (
                    <ul className="dropdown">
                        {sortedLevels.map((level, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    handleFilterChange('level', level);
                                    setShowLevelDropdown(false); // Close dropdown
                                }}
                            >
                                {level}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            
            {/* Time Dropdown */}
            <div className="filterTime" onClick={() => setShowTimeDropdown(!showTimeDropdown)}>
                Time: {selectedTime || "All"}
                {showTimeDropdown && (
                    <ul className="dropdown">
                        {sortedTimes.map((time, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    handleFilterChange('time', time);
                                    setShowTimeDropdown(false); // Close dropdown
                                }}
                            >
                                {time}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            
            {/* Reset Button */}
            <button className="resetButton" onClick={resetFilters}>
                X
            </button>
        </div>
    );
};

export default FilterRow;
