// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar {
    background-color: var(--search-bar-bg-color);
    min-height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 50%;
    border-radius: 20px;
    padding: 1%;

    border: 2px var(--search-bar-border-color) solid;


}

.searchBarText {
    margin: 1%;
    border: transparent;
    background-color: transparent;
    flex-direction: row;
    flex-grow: 1;
    color: var(--search-bar-text-color);
}

.searchBarText:focus {
    outline: none !important;
}`, "",{"version":3,"sources":["webpack://./src/css/BaseElements/TopBar/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,UAAU;IACV,mBAAmB;IACnB,WAAW;;IAEX,gDAAgD;;;AAGpD;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,mCAAmC;AACvC;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".searchBar {\n    background-color: var(--search-bar-bg-color);\n    min-height: fit-content;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    text-align: start;\n    width: 50%;\n    border-radius: 20px;\n    padding: 1%;\n\n    border: 2px var(--search-bar-border-color) solid;\n\n\n}\n\n.searchBarText {\n    margin: 1%;\n    border: transparent;\n    background-color: transparent;\n    flex-direction: row;\n    flex-grow: 1;\n    color: var(--search-bar-text-color);\n}\n\n.searchBarText:focus {\n    outline: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
