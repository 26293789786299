import '../css/GeneralCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import '../css/ActivityBuilder.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import DownloadButton from './Buttons/DownloadButton.js';
function ActivityListElement( {name, progress, onClick, percentage} ) {
  return (
    <div className='activityInActivityBuilder'>
        <div className='exerciseNameInPatientViewer'>{name}</div>
        <DownloadButton onClick={onClick} showOnlyIcon={true}></DownloadButton>
        {progress!=null&&<div className='levelText'> {progress}</div>}
        {percentage!=null&&<div className='levelText'>{Math.round(percentage)}%</div>}
    </div>
   
  );
}

export default ActivityListElement;
