import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Buttons/Buttons.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

function OpenIconButton( {onClick} ) {
    return (
      <button onClick={onClick} className={'openIconButton'}> 
        <i className="bi bi-arrow-right"/>
        </button>
    );
  }
  export default OpenIconButton;