// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --download-button-font-size: large;
    --login-button-font-size: large;
    --save-activity-button-font-size: large;
    --new-card-button-font-size: x-large;
    --new-card-button-font-weight: 600;
    --activity-card-font-size: large;
}

.loginWelcomeText {
    font-size: xx-large;
    color: var(--main-blue);
    font-weight: 600;
}

.loginLabelText {
    font-size: large;
}

.textCard {
    font-size: medium;
    color: var(--main-white);
}

.labelCard {
    font-size: large;
    color: var(--main-white);
}


p, a, label, h1, h2, h3, h4, button, input {
    text-wrap: wrap;
    word-break: break-all;
}

.errorMessage {
    color: var(--error-message-color);
    font-size: medium;
    align-self: center;
}

:root {
    --main-font: Fredoka;

}
`, "",{"version":3,"sources":["webpack://./src/css/Text.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,uCAAuC;IACvC,oCAAoC;IACpC,kCAAkC;IAClC,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B;;;AAGA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;;AAExB","sourcesContent":[":root {\n    --download-button-font-size: large;\n    --login-button-font-size: large;\n    --save-activity-button-font-size: large;\n    --new-card-button-font-size: x-large;\n    --new-card-button-font-weight: 600;\n    --activity-card-font-size: large;\n}\n\n.loginWelcomeText {\n    font-size: xx-large;\n    color: var(--main-blue);\n    font-weight: 600;\n}\n\n.loginLabelText {\n    font-size: large;\n}\n\n.textCard {\n    font-size: medium;\n    color: var(--main-white);\n}\n\n.labelCard {\n    font-size: large;\n    color: var(--main-white);\n}\n\n\np, a, label, h1, h2, h3, h4, button, input {\n    text-wrap: wrap;\n    word-break: break-all;\n}\n\n.errorMessage {\n    color: var(--error-message-color);\n    font-size: medium;\n    align-self: center;\n}\n\n:root {\n    --main-font: Fredoka;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
