import '../css/GeneralCSS.css'
import '../css/CardCSS.css'
import '../css/Colors.css'
import '../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function PatientBubble( {patientCode, canRemove, onRemove, onClick} ) {
  return (
    <div className='patientBubbleArea'>
        {canRemove&&<button className='patientBubbleRemove' onClick={onRemove}>-</button>}
        <div className='patientBubble'>
        <button className="patientBubbleCode" onClick={onClick}> {patientCode} </button>
        </div>
    </div>
   
  );
}

export default PatientBubble;
