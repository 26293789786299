import React, { useEffect, useState } from 'react';
import '../css/Home.css';
import '../css/GeneralCSS.css';
import '../css/Text.css';
import '../css/Colors.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/ActivityBuilder.css';
import PatientBubble from '../BaseElements/PatientBubble.js';
import SaveActivityButton from '../BaseElements/Buttons/SaveActivityButton.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TopBar from '../BaseElements/TopBar/TopBar.js';
import FilterRow from '../BaseElements/Filters/FilterRow';
import ExerciseListElement from "../BaseElements/ExerciseListElement";

axios.defaults.withCredentials = true;

function CreateActivity() {
    const navigate = useNavigate();
    const [patientList, setPatientList] = useState([]);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [activityName, setActivityName] = useState('');
    const [selectedExercises, setSelectedExercises] = useState([]);
    const [exercisesList, setExercisesList] = useState([]);
    
    const [selectedType, setSelectedType] = useState();
    const [selectedLevel, setSelectedLevel] = useState();
    const [selectedTime, setSelectedTime] = useState();
    
    const username = localStorage.getItem('username');
    
    useEffect(() => {
        loadData();
    }, []);
    
    const loadData = async () => {
        const axiosConfig = {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' }
        };
        try {
            const [patientsResponse, exercisesResponse] = await Promise.all([
                                                                                axios.get(`${process.env.REACT_APP_API_URL}/api/caregiverPatients`, axiosConfig),
                                                                                axios.get(`${process.env.REACT_APP_API_URL}/api/getAllExercises`, axiosConfig)
                                                                            ]);
            if (patientsResponse.data.success && exercisesResponse.data.success) {
                setPatientList(patientsResponse.data.data.map(patient => patient[0].toString()));
                
                setExercisesList(
                    exercisesResponse.data.data.map(exercise => {
                        const specificData = exercise[4];
                        const time = Object.values(specificData)[0]?.time || 'N/A';
                        return {
                            id: exercise[0],
                            name: exercise[1],
                            type: exercise[2],
                            level: exercise[3],
                            time
                        };
                    })
                );
            } else {
                console.error(patientsResponse.data.errorMess);
                console.error(exercisesResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    function manageFilterExercises(filterType, value) {
        switch (filterType) {
            case 'type':
                setSelectedType(value !== selectedType ? value : undefined);
                break;
            case 'level':
                setSelectedLevel(value !== selectedLevel ? value : undefined);
                break;
            case 'time':
                setSelectedTime(value !== selectedTime ? value : undefined);
                break;
            default:
                break;
        }
    }
    
    function reorderExercises(move, index) {
        const desiredPosition = move + index;
        if (desiredPosition < 0 || desiredPosition >= selectedExercises.length) return;
        setSelectedExercises(prev => {
            const newExercises = [...prev];
            [newExercises[desiredPosition], newExercises[index]] = [newExercises[index], newExercises[desiredPosition]];
            return newExercises;
        });
    }
    
    function addExerciseToActivity(newExercise) {
        if (selectedExercises.some(exercise => exercise.id === newExercise.id)) return;
        
        setSelectedExercises(prev => [...prev, newExercise]);
        setExercisesList(prev => prev.filter(exercise => exercise.id !== newExercise.id));
    }
    
    function removeExerciseToActivity(exercise) {
        setSelectedExercises(prev => prev.filter(e => e.id !== exercise.id));
        setExercisesList(prev => [...prev, exercise]);
    }
    
    function addUserToActivity(event, user) {
        if (event.code !== 'Enter') return;
        if (!patientList.includes(user) || selectedPatients.includes(user)) return;
        setSelectedPatients(prev => [...prev, user]);
    }
    
    function removeUserToActivity(user) {
        setSelectedPatients(prev => prev.filter(u => u !== user));
    }
    
    const saveActivity = async () => {
        if (!activityName) {
            alert('Inserisci il nome dell\'attività prima di continuare');
            return;
        }
        if (selectedExercises.length === 0) {
            alert('Inserisci almeno un esercizio prima di continuare');
            return;
        }
        
        try {
            const createResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/createActivity`,
                { activity_name: activityName, exercise_ids: selectedExercises.map(e => e.id) },
                { withCredentials: true, headers: { 'Content-Type': 'application/json' } }
            );
            
            if (createResponse.data.success) {
                const activityId = createResponse.data.data;
                alert('Attività creata correttamente');
                
                if (selectedPatients.length > 0) {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/associatePatientsToActivity`,
                        { activity_id: activityId, patient_ids: selectedPatients },
                        { withCredentials: true, headers: { 'Content-Type': 'application/json' } }
                    );
                }
            } else {
                console.error(createResponse.data.errorMess);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <div className='fullPageContainer'>
            <TopBar text={username} onMenu={() => navigate('/home')} showSearchBar={false} />
            <div className='exitTitleArea'>
                <div className='textCreateNewActivity'>Crea una nuova attività</div>
            </div>
            <div className='activityMainContent'>
                <div className='activityHalfContentColumn'>
                    <p className='instructionText'>Nome attività</p>
                    <input
                        className="assignPatientBar"
                        value={activityName}
                        onChange={e => setActivityName(e.target.value)}
                    />
                    <p className='instructionText'>Assegna ad un paziente</p>
                    <input className="assignPatientBar" onKeyDown={e => addUserToActivity(e, e.target.value)} />
                    <div className="assignedPatients">
                        {selectedPatients.map(user => (
                            <PatientBubble
                                key={user}
                                patientCode={user}
                                canRemove
                                onRemove={() => removeUserToActivity(user)}
                            />
                        ))}
                    </div>
                    <div className='exercisesListRowBuilder'>
                        <div className="exercisesList">
                            {selectedExercises.map((exercise, index) => (
                                <ExerciseListElement
                                    key={exercise.id}
                                    name={exercise.name}
                                    type={exercise.type}
                                    time={exercise.time}
                                    canDelete
                                    level={exercise.level}
                                    onRemoveExercise={() => removeExerciseToActivity(exercise)}
                                    onMoveListener={move => reorderExercises(move, index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className='activityHalfContentColumn'>
                    <FilterRow
                        types={[...new Set(exercisesList.map(e => e.type))]}
                        levels={[...new Set(exercisesList.map(e => e.level))]}
                        times={[...new Set(exercisesList.map(e => e.time))]}
                        onFilterChange={manageFilterExercises}
                    />
                    <div className="exercisesListRow">
                        <div className="exercisesList">
                            {exercisesList.filter(
                                    exercise =>
                                        (!selectedType || exercise.type === selectedType) &&
                                        (!selectedLevel || exercise.level === selectedLevel) &&
                                        (!selectedTime || exercise.time === selectedTime)
                                ).map(exercise => (
                                    <ExerciseListElement
                                        key={exercise.id}
                                        name={exercise.name}
                                        type={exercise.type}
                                        level={exercise.level}
                                        time={exercise.time}
                                        onAddExercise={() => addExerciseToActivity(exercise)}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='confirmButtonArea'>
                <SaveActivityButton onClick={saveActivity} />
            </div>
        </div>
    );
}

export default CreateActivity;
