import '../../css/GeneralCSS.css'
import '../../css/CardCSS.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import '../../css/BaseElements/Cards/Cards.css'
import OpenIconButton from '../Buttons/OpenIconButton';
function PatientCard( {text, info, onClick} ) {
  return (
    <div className='activityCard'>
      <p className='patientCardName'> Codice Paziente: </p>
      <p className='patientCardInfo'> {text} </p>
      {!info&&<div className='patientCardOpenInconRow'>
        <OpenIconButton onClick={onClick}/>
      </div>}
    </div>
   
  );
}

export default PatientCard;
