import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Buttons/Buttons.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

function DownloadButton( {onClick, showOnlyIcon} ) {
    return (
      <button onClick={onClick} className={'downloadButton'}> 
        <i className="bi bi-download"> {!showOnlyIcon&&"Scarica dati"} </i></button>
    );
  }
  export default DownloadButton;