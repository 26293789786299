// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base filter styles */
.defaultFilter, .clickedFilter {
    font-size: medium;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px 10px;
    margin: 3px;
    border: 3px solid transparent; /* Transparent border ensures consistent size */
    border-radius: 20px;
    box-sizing: border-box; /* Includes border and padding in element size */
    background-color: var(--filter-default-bg-color);
    color: var(--filter-default-text-color);
    transition: all 0.3s ease; /* Smooth transitions for hover effect */
}

/* Hover state for default filter */
.defaultFilter:hover {
    background-color: var(--filter-hovered-bg-color);
    color: var(--filter-hovered-text-color);
    border-color: var(--filter-hovered-border-color); /* Change border color only */
}

/* Clicked filter state */
.clickedFilter {
    background-color: var(--filter-clicked-bg-color);
    border: 3px solid transparent; /* Keep border consistent */
    color: var(--filter-clicked-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/css/BaseElements/Filters/Filter.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,6BAA6B,EAAE,+CAA+C;IAC9E,mBAAmB;IACnB,sBAAsB,EAAE,gDAAgD;IACxE,gDAAgD;IAChD,uCAAuC;IACvC,yBAAyB,EAAE,wCAAwC;AACvE;;AAEA,mCAAmC;AACnC;IACI,gDAAgD;IAChD,uCAAuC;IACvC,gDAAgD,EAAE,6BAA6B;AACnF;;AAEA,yBAAyB;AACzB;IACI,gDAAgD;IAChD,6BAA6B,EAAE,2BAA2B;IAC1D,uCAAuC;AAC3C","sourcesContent":["/* Base filter styles */\n.defaultFilter, .clickedFilter {\n    font-size: medium;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    padding: 2px 10px;\n    margin: 3px;\n    border: 3px solid transparent; /* Transparent border ensures consistent size */\n    border-radius: 20px;\n    box-sizing: border-box; /* Includes border and padding in element size */\n    background-color: var(--filter-default-bg-color);\n    color: var(--filter-default-text-color);\n    transition: all 0.3s ease; /* Smooth transitions for hover effect */\n}\n\n/* Hover state for default filter */\n.defaultFilter:hover {\n    background-color: var(--filter-hovered-bg-color);\n    color: var(--filter-hovered-text-color);\n    border-color: var(--filter-hovered-border-color); /* Change border color only */\n}\n\n/* Clicked filter state */\n.clickedFilter {\n    background-color: var(--filter-clicked-bg-color);\n    border: 3px solid transparent; /* Keep border consistent */\n    color: var(--filter-clicked-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
