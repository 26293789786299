import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Buttons/Buttons.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap-icons/font/bootstrap-icons.css';

function LoginButton( {onClick, isLoading} ) {
    return (
      <button className={'loginButton'} disabled={isLoading} onClick={onClick}>
        {!isLoading && ('Login')}
        {isLoading && (<Spinner animation="border" variant="light"/>)}
      </button>
    );
  }
  export default LoginButton;

