import React, { useEffect, useState } from "react";
import '../../css/BaseElements/TopBar/SearchBar.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'

const SearchBar = React.forwardRef(({ onChange }, ref) => {
const [value, setValue] = useState("");
  useEffect(() => {
    const handleResize = () => {
      const searchBarElement = document.getElementById("searchBar");
      if (searchBarElement) {
        const displayValue = getComputedStyle(searchBarElement).display;
        // Check if the element is hidden
        if (displayValue === "none") {
          handleChange(""); 
                }
      }
    };

    // Initial check on mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onChange]);


  function handleChange(text){
    setValue(text)
    onChange(text)
  }

  return (
    <div id="searchBar" className="searchBar" ref={ref}>
      <i className="bi bi-search"></i>
      <input
        className="searchBarText"
        value={value}
        onChange={(e) => handleChange((e.target.value))}
      />
    </div>
  );
});

export default SearchBar;
