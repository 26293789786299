
import '../../css/Colors.css'
import '../../css/Text.css'
import '../../css/BaseElements/TopBar/LogoutComponent.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function LogoutComponent( {text} ) {
  return (
    <div className='logoutComponent'>
      <p className='therapistName'> {text} </p>
    </div>
   
  );
}

export default LogoutComponent;
