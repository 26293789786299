const downloadLogs = (logs) => {
    try {
        console.log('Downloading logs:', logs); // Debugging to verify structure
        
        // Step 1: Determine the type of logs and construct the filename
        let filename;
        new Date().toISOString().replace(/[:.-]/g, '_');
        
        const patientIds = new Set(logs.map((log) => log[1]));
        const activityIds = new Set(logs.map((log) => log[0]));
        
        if (logs.length === 1) {
            const patientId = logs[0][1] || 'paziente-sconosciuto';
            const activityId = logs[0][0] || 'attivita-sconosciuta';
            filename = `registri_paziente-${patientId}_attivita-${activityId}.tsv`;
        } else if (patientIds.size === 1) {
            const patientId = Array.from(patientIds)[0] || 'paziente-sconosciuto';
            filename = `registri_paziente-${patientId}_tutte-le-attivita.tsv`;
        } else if (activityIds.size === 1) {
            const activityId = Array.from(activityIds)[0] || 'attivita-sconosciuta';
            filename = `registri_attivita-${activityId}_tutti-i-pazienti.tsv`;
        } else {
            filename = `registri_piu-pazienti_piu-attivita.tsv`;
        }
        
        const uniqueKeys = new Set(['ID Paziente', 'ID Attivita', 'ID Esercizio', 'Tipo di Esercizio', 'Progresso', 'Timestamp', 'Risposte Corrette']);
        logs.forEach((log) => {
            const logDetails = Array.isArray(log[2]) ? log[2] : [];
            logDetails.forEach((logEntry) => {
                if (Array.isArray(logEntry.actions)) {
                    logEntry.actions.forEach((action) => {
                        Object.keys(action).forEach((key) => uniqueKeys.add(key));
                    });
                }
            });
        });
        
        const headers = Array.from(uniqueKeys); // Convert the set to an array
        const tsvRows = [headers.join('\t')]; // Tab-separated headers
        
        logs.forEach((log) => {
            let numOfParts = 0;
            let correctParts = 0;
            const patientId = log[1] || 'N/A';
            const activityId = log[0] || 'N/A';
            const logDetails = Array.isArray(log[2]) ? log[2] : [];
            
            logDetails.forEach((logEntry) => {
                const exerciseId = logEntry.exercise_id || 'N/A';
                const exerciseType = logEntry.exercise_type || 'N/A';
                const progress = `${logEntry.progress || 0}%`;
                
                if (Array.isArray(logEntry.actions) && logEntry.actions.length > 0) {
                    logEntry.actions.forEach((action) => {
                        const row = headers.map((key) => {
                            if (key === 'ID Paziente') return patientId;
                            if (key === 'ID Attivita') return activityId;
                            if (key === 'ID Esercizio') return exerciseId;
                            if (key === 'Tipo di Esercizio') return exerciseType;
                            if (key === 'Progresso') return progress;
                            if (key === 'Timestamp') return action.timestamp ? new Date(action.timestamp).toLocaleString() : 'N/A';
                            
                            // Handle special cases like arrays or objects in fields
                            const value = action[key];
                            if (Array.isArray(value)) {
                                return value.map((item) => {
                                    if (typeof item === 'object') {
                                        return Object.values(item).join(':');
                                    }
                                    return item;
                                }).join(' - '); // Join array items with a separator
                            }
                            if (typeof value === 'object' && value !== null) {
                                return JSON.stringify(value); // Convert objects to string representation
                            }
                            return value !== undefined ? value : 'N/A';
                        });
                        tsvRows.push(row.join('\t')); // Tab-separated rows
                        if(action.correct) correctParts += 1;
                        numOfParts += 1;
                    });
                } else {
                    // Add a row with default values when there are no actions
                    const row = headers.map((key) => {
                        if (key === 'ID Paziente') return patientId;
                        if (key === 'ID Attivita') return activityId;
                        if (key === 'ID Esercizio') return exerciseId;
                        if (key === 'Tipo di Esercizio') return exerciseType;
                        if (key === 'Progresso') return progress;
                        if (key === 'Timestamp') return 'N/A';
                        return 'N/A';
                    });
                    tsvRows.push(row.join('\t'));
                }
            });
            const row = headers.map((key) => {
                if (key === 'ID Paziente') return patientId;
                if (key === 'ID Attivita') return activityId;
                if (key === 'Risposte Corrette') return `${Math.round(correctParts/numOfParts*100)}%`;
                return '';
            });
            tsvRows.push(row.join('\t'));
        });
        
        const tsvContent = '\uFEFF' + tsvRows.join('\n'); // Add BOM for UTF-8 compatibility
        
        // Step 4: Create and download the file
        const blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
        console.error('Error generating logs file:', error);
    }
};

export default downloadLogs;
