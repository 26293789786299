import '../../css/GeneralCSS.css'
import '../../css/CardCSS.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
function NewCard( {text, onClick} ) {
  return (
    <button className='newCardButton' onClick={onClick}>
      <i className="bi bi-plus-circle-fill"> </i>
      {text}
    </button>
  );
}

export default NewCard;
