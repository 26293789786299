import '../../css/GeneralCSS.css'
import '../../css/BaseElements/Buttons/Buttons.css'
import '../../css/Images.css'
import '../../css/Colors.css'
import '../../css/Text.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

function DownloadButton( {onClick} ) {
    return (
      <button onClick={onClick} className={'homeIconButton'}> 
        <i className="bi bi-house-door-fill"/></button>
    );
  }
  export default DownloadButton;