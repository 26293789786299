function TitleBarPatient( {name, download, answer} ) {
    return (
        <div className='titleBarPatient'>
            <div className='activityTitleName'>{name}</div>
            <div className='activityDownloadName'>{download}</div>
            <div className='activityAnswersName'>{answer}</div>
        </div>
    
    );
}

export default TitleBarPatient;
